// @flow
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import Image from '../../components/image';
import Link from '../../components/link';
import GlossaryLink from '../../components/glossary-link';
import route from '../../routes';
import type { FrontMatter, ImageType } from '../../utils/types';
import { withFrontMatter } from '../../components';

type Props = {
  frontMatter: FrontMatter,
  data: {
    _1: ImageType,
    _2: ImageType,
  },
};

class Blog_2018_04_15_One_Hundred_Canyons_Later extends React.Component<Props> {
  render() {
    const { data, frontMatter } = this.props;

    return (
      <Layout frontMatter={frontMatter}>
        <p>
          Review of and love letter to my recently retired Petzl Canyon{' '}
          <GlossaryLink>harness</GlossaryLink>.
        </p>
        <p>
          We went through well over 100 canyons together. 52 of them were North
          Wash, Escalante, or otherwise skinny canyons.
        </p>
        <p>
          Sandthrax started your death. I didn't repair you when I had the
          opportunity and took you out again when I shouldn't have. Escalante
          sealed your fate. Or at least your butt protector's fate. Duct tape
          has fixed you many times but it can't fix this. I'm sorry for breaking
          you.
        </p>
        <p>
          The thought of replacing you makes me worry that I won't like the next
          one nearly as much. When I first came to the realization that duct
          tape wouldn't fix you this time, I felt empty inside. I still feel a
          little empty, even though it's been several weeks now.
        </p>
        <hr />
        <p>
          When I got you, there were a several things I didn't like and a couple
          I did.
        </p>
        <h2>The Bad</h2>
        <h3>You are heavy</h3>
        <p>
          You weigh more than any climbing or canyoneering harness I know of.
          Not the best thing on a backpacking trip.
        </p>
        <h3>You are bulky</h3>
        <p>
          You take up way more space than any harness should take. I would hike
          for miles with you still on me because you took up so much space I
          didn't have any room for you in my backpack. 12 miles in
          <Link to={route.blog_2016_05_14_neon_choprock}>Choprock</Link>. I took
          a different harness for{' '}
          <Link to={route.blog_2017_06_10_heaps}>Heaps</Link> because you're so
          big.
        </p>
        <h3>Your monstrous gear loops</h3>
        <p>
          Your <GlossaryLink>gear loops</GlossaryLink> are so big I started
          buying different carabiners because it was annoying to try and fit
          Petzl Attaches on there.
        </p>
        <p>
          Also your <GlossaryLink>waist belt</GlossaryLink> is too long and your
          <GlossaryLink>leg loops</GlossaryLink> are too short.
        </p>
        <h3>You absorb a ton of water</h3>
        <p>
          You have lots of padding that makes the harness weigh twice as much
          the minute I enter water.
        </p>
        <h3>The butt protector looks like a diaper</h3>
        <p>
          You have these elastic bands on the side of your butt protector that
          scrunch it up. The good news is they didn't last 10 canyons.
        </p>
        <p>
          At least it's not white with a yellow stripe that turns blue when it
          gets wet. Don't get any ideas.
        </p>
        <h2>The Good</h2>
        <h3>You've been my reliable friend</h3>
        <p>
          You have never faltered. You have functioned exactly according to your
          design. Your gear loops never broke. Your belay loops never broke.
          Your waist belt and leg loops never broke. You always fit me well
          enough.
        </p>
        <h3>I look like a badass</h3>
        <p>
          When you go canyoneering with people for the first time, harnessing up
          is also the time canyoneers size each other up. You, my destroyed
          harness, provide me with instant credibility.
        </p>
        <h3>You lasted 100 canyons</h3>
        <p>
          We went through over <em>100 canyons</em>. Holy cow! Most harness
          don't last half that many canyons.
        </p>
        <h2>Next harness</h2>
        <p>
          I am tempted to just get another butt protector for you and I still
          may do this. There are quite a few scars on your leg loops that are
          starting to look a little sketchy, though. And I don't know how long
          the belay loop will hold. You are already five years old. That's
          pretty old in canyon years.
        </p>
        <p>
          I ended up getting a Singing Rock Canyon XP harness. The gear loops
          are a lot smaller and I've heard complaints that they break easily. I
          know I take care of my stuff, though, so I think it'll be okay. I also
          don't put every conceivable piece of gear on them for every canyon so
          I have that natural advantage.
        </p>
        <Image image={data._1} />
        <Image image={data._2} />
      </Layout>
    );
  }
}

export default withFrontMatter('2018-04-15-one-hundred-canyons-later')(
  Blog_2018_04_15_One_Hundred_Canyons_Later
);

export const query = graphql`
  query {
    _1: file(
      relativePath: {
        eq: "images/blog/2018-04-15-one-hundred-canyons-later/1.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _2: file(
      relativePath: {
        eq: "images/blog/2018-04-15-one-hundred-canyons-later/2.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
